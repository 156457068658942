<template>
  <div class="page__container">
    <div class="wrapper">
      <Backlink title="footer__return_policy" />
      <div class="content">
        <div class="content__container">
          <TextBlock :id="8" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'
import TextBlock from '@/components/Text.vue'

export default {
  name: 'Return_policy',
  components: { Backlink, TextBlock }
}
</script>
